import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Become functional – Basic functions",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:55:39.000Z",
  "url": "/2018/02/become-functional-basic-functions/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Functional programming becomes the norm, and it has a great hype around it now.`}</p>
    <p>{`As I mostly used that with redux to make sure my reducers work properly, those function will be helpful for anyone who needs some functional javascript functions.`}</p>
    <p>{`I`}{`’`}{`ll show some examples below on some simple actions we use like: push to array, remove from array, update item in array and updating object.`}</p>
    <p>{`The first action will be the mutating one, and the rest would be functional non mutating ones.`}</p>
    <h6>{`Push to array {#pushtoarray}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//******** this mutates original list !!!!!!! *******
const simplePush = (list, item) => {  
    list.push(item);
    return list;
};

const concatPush = (list, item) => {  
    return list.concat([item]);
};

const es6Push = (list, item) => {  
    return [...list, item];
};
`}</code></pre>
    <h6>{`Remove from array {#removefromarray}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//******** this mutates original list !!!!!!! *******
const simpleRemove = (list, index) => {  
    list.splice(index, 1);
    return list;
};

const sliceRemove = (list, index) => {  
    return list
        .slice(list, index)
        .concat(list.slice(index+1));
};

const es6Remove = (list, index) => {  
    return [
        ...list.slice(0, index),
        ...list.slice(index + 1)
    ];
};
`}</code></pre>
    <h6>{`Update array item {#updatearrayitem}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//******** this mutates original list !!!!!!! *******
const simpleUpdate = (list, index, value) => {  
    list[index] = value;
    return list;
};

const sliceUpdate = (list, index, value) => {  
    return list
        .slice(list, index)
        .concat([value])
        .concat(list.slice(index+1));
};

const es6Update = (list, index, value) => {  
    return [
        ...list.slice(0, index),
        value,
        ...list.slice(index + 1)
    ];
};
`}</code></pre>
    <h6>{`Update object value {#updateobjectvalue}`}</h6>
    <pre><code parentName="pre" {...{}}>{`//******** this mutates original list !!!!!!! *******
const simpleUpdate = (obj, key, val) => {  
    obj[key] = val;
    return obj;
};

const assignUpdate = (obj, key, val) => {  
    let updateObj = {};
    updateObj[key] = val;
    return Object.assign({}, obj, updateObj);
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      